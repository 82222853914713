// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("D:\\a\\1\\s\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-general-terms-js": () => import("D:\\a\\1\\s\\src\\pages\\general-terms.js" /* webpackChunkName: "component---src-pages-general-terms-js" */),
  "component---src-pages-index-js": () => import("D:\\a\\1\\s\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("D:\\a\\1\\s\\src\\pages\\news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-policy-js": () => import("D:\\a\\1\\s\\src\\pages\\privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

exports.data = () => import("D:\\a\\1\\s\\.cache\\data.json")

